<template>
  <v-layout row justify-center>
    <v-dialog v-model="NewMarqueDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline cyan darken-1" primary-title>
          <h4 class="mb-3 justify-center white--text">
            Ajoute Nouvelle Marque
          </h4>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-col class="justify-center">
                <v-text-field
                  cols="12"
                  sm="6"
                  v-model="MarkName"
                  @change="resetImageName()"
                  label="Nom de la Marque"
                ></v-text-field>
              </v-col>
              <v-col class="justify-center">
                <v-row class="justify-center">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="MarkLevel"
                      label="Level"
                      outlined
                      type="number"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="MarkParentId"
                      label="ParentId"
                      outlined
                      type="number"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="justify-center">
                <v-row class="justify-center">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="MarkOrder"
                      label="Order"
                      outlined
                      type="number"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="MarkExternalId"
                      label="External Id"
                      outlined
                      clearable
                      :rules="marqueRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="justify-center">
                <v-text-field
                  v-model="MarkLibelle"
                  label="libelle"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>

              <v-col class="justify-center">
                <v-img
                  v-if="this.imageMark"
                  style="margin-left: 140px"
                  contain
                  max-height="295"
                  max-width="210"
                  :src="this.imageMark"
                ></v-img>

                <v-file-input
                  class="justify-center"
                  v-if="this.MarkName"
                  hide-input
                  prepend-icon="mdi-camera"
                  v-model="images"
                  label="Remplacer l'image"
                  @change="uploadImageNewMarque()"
                  @click:clear="callItBack()"
                ></v-file-input>
              </v-col>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()"> Annuler </v-btn>

          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-6"
                color="blue darken-1"
                text
                v-bind="attrs"
                v-on="on"
                :disabled="
                  !valid ||
                  (imageMark && imageMark.trim() === '') ||
                  !imageMark ||
                  (imageMark && imageMark.match(/^ *$/)) ||
                  (MarkName && MarkName.trim() === '') ||
                  !MarkName ||
                  (MarkName && MarkName.match(/^ *$/)) ||
                  (MarkOrder && MarkOrder.trim() === '') ||
                  !MarkOrder ||
                  (MarkOrder && MarkOrder.match(/^ *$/)) ||
                  (MarkLevel && MarkLevel.trim() === '') ||
                  !MarkLevel ||
                  (MarkLevel && MarkLevel.match(/^ *$/)) ||
                  (MarkLibelle && MarkLibelle.trim() === '') ||
                  !MarkLibelle ||
                  (MarkLibelle && MarkLibelle.match(/^ *$/)) ||
                  (MarkParentId && MarkParentId.trim() === '') ||
                  !MarkParentId ||
                  (MarkParentId && MarkParentId.match(/^ *$/))
                "
              >
                Confirmer
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline cyan darken-1" primary-title>
                <h4 class="mb-3 justify-center white--text">Confirmation</h4>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  Êtes vous sur d'ajouter une nouvelle Marque portant le nom

                  <span style="text-decoration: underline; color: #00acc1">{{
                    MarkName
                  }}</span>
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-2" text @click="dialog = false">
                  Non
                </v-btn>
                <v-btn
                  color="#19b4bf"
                  text
                  v-on:click.prevent="addMarkInFirebase()"
                >
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { firebase } from "@firebase/app";
import NodesName from "../../../utils/nodesName";
import NodesFieldName from "../../../utils/nodesFieldName";
import "firebase/firestore";
import db from "../../../../firebaseInit";
import FunctionsUtils from "../../../utils/functions";

export default {
  name: "AddMarque",
  data() {
    return {
      marqueRules: [
        (v) => !!v || "External Id est requis. ",
        (v) =>
          (v || "").indexOf(" ") < 0 || "Les espaces ne sont pas autorisés.",
      ],
      userID: null,
      images: [],
      image: [],
      dialog: false,
      MarkName: null,
      MarkLibelle: null,
      MarkLevel: null,
      MarkOrder: null,
      MarkExternalId: null,
      MarkParentId: null,
      MarkSubCategory: [],
      MarkCategory: [],
      imageMark: null,
      valid: false,
    };
  },
  props: {
    NewMarqueDialog: {
      default: false,
    },
  },
  async beforeMount() {
    this.userID = localStorage.getItem("adminID");
  },
  methods: {
    resetImageName() {
      this.imageMark = null;
      this.images = null;
    },
    async addMarkInFirebase() {
      let catalogMark = db
        .collection(NodesName.PRODUCT_CATALOG)
        .doc(FunctionsUtils.minimizeFirstLetter(this.MarkExternalId));
      let MarkExists = await catalogMark.get();
      if (!MarkExists.exists) {
        catalogMark.set({});
      } else if (MarkExists.exists) {
        console.log("mark", this.MarkName, "Already Exists in Cataloge");
      }
      var FirebaseMarkRef = db
        .collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .doc();
      db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(
          NodesFieldName.NAME,
          "==",
          FunctionsUtils.capitalizeFirstLetter(this.MarkName)
        )
        .where(NodesFieldName.DELETED, "==", false)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size == 0) {
            FirebaseMarkRef.set({
              name: FunctionsUtils.capitalizeFirstLetter(this.MarkName),
              image: this.imageMark,
              deleted: false,
              uniqueId: FirebaseMarkRef.id,
              createdBy: this.userID,
              creationDate: new Date(),
              level: Number(this.MarkLevel),
              order: Number(this.MarkOrder),
              externalId: FunctionsUtils.minimizeFirstLetter(
                this.MarkExternalId
              ),
              parentId: Number(this.MarkParentId),
              libelle: FunctionsUtils.capitalizeFirstLetter(this.MarkLibelle),
              category: this.MarkCategory,
            })
              .then(() => {
                this.dialog = false;
                this.close();
                this.$emit("Refrech");
              })
              .catch(function (error) {
                console.error("Error adding document: ", error);
              });
          } else {
            confirm("la marque existe déjà ");
            this.dialog = false;
          }
        });
    },
    async uploadImageNewMarque() {
      await firebase
        .storage()
        .ref("mark_logo/" + this.MarkName + "/" + "/" + this.images.name)
        .put(this.images)
        .then((response) => {
          firebase
            .storage()
            .ref("mark_logo/" + this.MarkName + "/" + "/" + this.images.name)
            .getDownloadURL()
            .then((url) => {
              this.imageMark = url;
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      this.uploaded = true;
    },
    close() {
      this.MarkName = null;
      this.MarkLibelle = null;
      this.MarkLevel = null;
      this.MarkOrder = null;
      this.MarkExternalId = null;
      this.MarkParentId = null;
      this.imageMark = null;
      this.$emit("update:NewMarqueDialog", false);
    },
  },
  props: {
    NewMarqueDialog: {
      type: Boolean,
    },
  },
};
</script>
