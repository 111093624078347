<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="AssignedCategoryDialog"
      style="min-height: 600px"
      persistent
      max-width="600"
    >
      <v-card style="min-height: 600px" hover height="100%" class="card-outter">
        <v-card-title class="headline cyan darken-1" primary-title>
          <h4 class="mb-3 justify-center white--text">
            Modification de la liste des catégories
            <v-spacer></v-spacer> assignées à la marque :<v-spacer></v-spacer>"
            {{ Mark.name }}"
          </h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <!-- Liste des catégories assignées -->
          <v-col v-if="Mark.assignedCategoryName" class="justify-center">
            <h2 class="text-center" v-if="Mark.assignedCategoryName.length > 0">
              <v-icon color="#5cbbf6">mdi-label</v-icon> Liste des catégories
              assignées :
            </h2>

            <v-chip
              @click:close="SupprimerCategory(Category)"
              color="#5cbbf6"
              text-color="white"
              close
              class="ml-2 mt-2"
              v-for="Category in Mark.assignedCategoryName"
              :key="Category.id"
            >
              <v-icon color="white" left> mdi-label </v-icon>
              <span style="font-size: 1.1em"> {{ Category }} </span>
            </v-chip>

            <!-- Suppression catégories assigné -->
            <v-dialog v-model="deleteDialog" width="500px">
              <v-card>
                <v-card-title class="headline cyan darken-1" primary-title>
                  <h4 class="mb-3 justify-center white--text">Confirmation</h4>
                </v-card-title>
                <v-card-text>
                  <div class="text-center">
                    Êtes vous sur de supprimer la categorie "
                    <span style="text-decoration: underline; color: #00acc1">
                      {{ this.selectedCategory[0] }}
                    </span>
                    " de la marque "
                    <span style="text-decoration: underline; color: #00acc1"
                      >{{ Mark.name }} "</span
                    >
                  </div>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red lighten-2"
                    text
                    @click="deleteDialog = false"
                  >
                    Non
                  </v-btn>
                  <v-btn
                    color="#19b4bf"
                    text
                    v-on:click.prevent="ValiderSupprimerCategory()"
                  >
                    Oui
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Suppression catégories assigné -->
          </v-col>
          <v-divider></v-divider>
          <!-- Assignation nouvelles catégories  -->
          <v-col class="justify-center">
            <h2 class="text-center">
              <v-icon color="#5cbbf6">mdi-label</v-icon> Assigner une nouvelle
              catégorie :
            </h2>
            <h5 class="text-center" style="font-size: 0.8em; margin-left: 5px">
              <v-icon style="margin-bottom: 5px" color="#f53030">
                mdi-alert</v-icon
              >
              NB: l'ajout d'un nouveau produit au catalogue dépend de
              l'affectation des marques aux catégories
            </h5>
          </v-col>
          <v-col class="justify-center">
            <multiselect
              v-model="newsCategory"
              :close-on-select="false"
              :multiple="true"
              :group-select="true"
              :options="CategoryDifference"
              placeholder="sélectionnez Catégories "
            ></multiselect>
          </v-col>
        </v-container>
        <v-card-actions class="card-actions">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()"> Annuler </v-btn>
          <v-btn
            :disabled="this.newsCategory.length < 1"
            class="ml-6"
            color="blue darken-1"
            text
            @click="dialog = true"
          >
            Confirmer
          </v-btn>
          <!-- Confirmation assignation nouvelles catégories  -->
          <v-dialog v-model="dialog" width="500px">
            <v-card>
              <v-card-title class="headline cyan darken-1" primary-title>
                <h4 class="mb-3 justify-center white--text">Confirmation</h4>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  Êtes vous sur d'assigné ses categories à la mark
                  <span
                    style="justify-center; text-decoration: underline; color: #00acc1"
                  >
                    {{ Mark.name }}
                  </span>
                </div>
                <div class="text-center">
                  <v-chip
                    color="#5cbbf6"
                    text-color="white"
                    class="ml-2 mt-2"
                    v-for="newsCategory in newsCategory"
                    :key="newsCategory"
                  >
                    <v-icon color="white" left> mdi-label </v-icon>
                    {{ newsCategory }}
                  </v-chip>
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-2" text @click="dialog = false">
                  Non
                </v-btn>
                <v-btn
                  color="#19b4bf"
                  text
                  v-on:click.prevent="ConfirmerAssignedCategorys()"
                >
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fin Assignation nouvelles catégories  -->
  </v-layout>
</template>

<script>
import { firebase } from "@firebase/app";
import NodesName from "../../../utils/nodesName";
import NodesFieldName from "../../../utils/nodesFieldName";
import "firebase/firestore";
import db from "../../../../firebaseInit";
import Multiselect from "vue-multiselect";
import FunctionsUtils from "../../../utils/functions";
export default {
  name: "AssignedCategoryModal",
  components: {
    Multiselect,
  },
  data() {
    return {
      deleteDialog: false,
      dialog: false,
      newsCategory: [],
      selectedCategory: [],
      categoryId: [],
    };
  },
  // Importation UserID
  async beforeMount() {
    this.userID = localStorage.getItem("adminID");
  },
  methods: {
    // suppression catégories assigné
    SupprimerCategory(Category) {
      this.selectedCategory[0] = Category;
      this.deleteDialog = true;
    },
    //Confirmation suppression catégories assigné
    async ValiderSupprimerCategory() {
      this.Mark.assignedCategoryName.splice(
        this.Mark.assignedCategoryName.indexOf(
          FunctionsUtils.getassignedCategoryName(
            this.selectedCategory,
            this.CategoriesListmap
          )
        ),
        1
      );
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.UNIQUE_ID, "==", this.Mark.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                category: firebase.firestore.FieldValue.arrayRemove(
                  FunctionsUtils.getassignedCategoryid(
                    this.selectedCategory,
                    this.CategoriesListmap
                  )[0]
                ),
              })
              .then(() => {
                this.deleteDialog = false;
                this.selectedCategory = [];
              });
          });
        });
    },
    //Confirmation Ajoute nouvelles categories
    async ConfirmerAssignedCategorys() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.UNIQUE_ID, "==", this.Mark.id)
        .where(NodesFieldName.DELETED, "==", false)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                category: firebase.firestore.FieldValue.arrayUnion(
                  ...FunctionsUtils.getassignedCategoryid(
                    this.newsCategory,
                    this.CategoriesListmap
                  )
                ),
              })
              .then(() => {
                this.close();
              });
          });
        });
    },
    //Annuler Modifiction
    close() {
      this.$emit("update:AssignedCategoryDialog", false);
      this.newsCategory = [];
      this.$emit("RefrechCategory");
    },
  },
  // Importer props(Marque Selectionner,Liste des catégories assignées, Liste des catégories non assigné  )
  props: {
    //Marque Selectionner
    Mark: {
      required: true,
    },
    //Liste des catégories assignées
    AssignedCategoryDialog: {
      type: Boolean,
    },
    //  Liste des catégories non assigné
    CategoryDifference: {},
    CategoriesListmap: {},
  },
};
</script>
<style>
@import "@/../../../../../sass/multiselectStyle.css";
</style>
