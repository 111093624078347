<template>
  <div id="Marque">
    <v-container class="lighten-5">
      <dashboard-core-app-bar />
    </v-container>
    <v-row style="margin-top: 20px">
      <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>

      <!-- Ajoute Nouvelle Marque  -->
      <v-col cols="12" md="12">
        <v-btn @click="NewMarqueDialog = true" color="#19b4bf" dark>
          <v-icon class="ma-1"> mdi-plus-box </v-icon>
          Ajoute Nouvelle Marque</v-btn
        >
        <NewMarqueModal
          :NewMarqueDialog.sync="NewMarqueDialog"
          @Refrech="clear()"
        />
      </v-col>
      <!--Fin Ajoute Nouvelle Marque  -->
      <!-- Liste des filtres  -->
      <v-col cols="12" md="12">
        <v-card class="pa-3" outlined tile>
          <v-card-text>
            <v-col>
              <v-row>
                <!-- Filtre par Categories -->
                <v-col style="margin-top: 15px" cols="6" md="2">
                  <v-autocomplete
                    :items="CategoriesList"
                    item-value="id"
                    item-text="name"
                    label="Catégories"
                    chips
                    clearable
                    hide-details
                    hide-selected
                    v-model="categoryfilter"
                    @input="onCategoryChange(categoryfilter)"
                  ></v-autocomplete>
                </v-col>
                <!-- Filtre par Nom -->

                <v-col style="margin-top: 15px" cols="3" md="2">
                  <v-radio-group mandatory>
                    <v-radio
                      label="Trier de A à Z"
                      @change="onFilterChange('Trier de A à Z')"
                    ></v-radio>
                    <v-radio
                      label="Trier de Z à A"
                      @change="onFilterChange('Trier de Z à A')"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
        <!-- Récupération liste des Marque -->
        <v-card>
          <v-container>
            <v-layout row wrap>
              <v-flex
                xs12
                sm6
                md4
                v-for="MarqueListe in MarquesListe"
                :key="MarqueListe.id"
              >
                <v-card min-height="450px" style="margin: 20px" elevation="2">
                  <v-list-item>
                    <v-spacer></v-spacer>
                  </v-list-item>
                  <v-card style="margin: 5px" height="200" elevation="0">
                    <v-col
                      height="400"
                      class="
                        fill-height
                        d-flex
                        flex-column
                        justify-center
                        align-center
                      "
                    >
                      <!--  Marque Image -->
                      <v-card elevation="0">
                        <v-img
                          style="cursor: pointer"
                          @click="redirectionMarkDetails(MarqueListe)"
                          max-width="132"
                          :src="MarqueListe.src"
                        >
                        </v-img>
                      </v-card>
                    </v-col>
                  </v-card>
                  <!--  Marque Nom -->
                  <v-card-title
                    style="cursor: pointer"
                    primary-title
                    class="justify-center"
                  >
                    <span
                      @click="redirectionMarkDetails(MarqueListe)"
                      class="primary--text"
                      >{{ MarqueListe.name }}</span
                    ></v-card-title
                  >
                  <v-divider></v-divider>
                  <!-- Liste des Categories assigné -->

                  <v-card-title primary-title class="mt-3 justify-center">
                    Categories:
                    <v-icon
                      @click="editCategory(MarqueListe)"
                      color="#19b4bf"
                      style="margin-top: 5px"
                    >
                      mdi-database-plus
                    </v-icon></v-card-title
                  >

                  <v-card-text
                    class="text-center"
                    v-if="
                      MarqueListe.assignedCategoryName.indexOf(undefined) === -1
                    "
                  >
                    <v-chip
                      color="#5cbbf6"
                      text-color="white"
                      class="ml-2 mt-2"
                      v-for="list in MarqueListe.assignedCategoryName"
                      :key="list.id"
                    >
                      <v-icon color="white" left> mdi-label </v-icon>
                      <span style="font-size: 1.1em"> {{ list }} </span>
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-flex>
              <!--  Dialog pour ajoute et modification l'assignation des Catégories  -->
              <AssignedCategoryModal
                :AssignedCategoryDialog.sync="AssignedCategoryDialog"
                :Mark="sendMarqueInfo"
                :CategoryDifference="CategoryDifference"
                @RefrechCategory="clear()"
                :CategoriesListmap="CategoriesListmap"
              />
            </v-layout>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <!-- Pagination Liste des Mark  -->
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-btn
                @click="onPaginationGetMark()"
                class="ma-2"
                outlined
                large
                fab
                color="#19b4bf"
              >
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import NodesName from "../../utils/nodesName";
import NodesFieldName from "../../utils/nodesFieldName";
import "vue-hotel-datepicker2/dist/vueHotelDatepicker2.css";
import "firebase/firestore";
import db from "../../../firebaseInit";
import Multiselect from "vue-multiselect";
import nodesFieldName from "../../utils/nodesFieldName";
import FunctionsUtils from "../../utils/functions";
import NewMarqueModal from "../../admin/components/core/NewMarqueModal.vue";
import AssignedCategoryModal from "../../admin/components/core/AssignedCategoryModal.vue";
import EditMarqueModal from "../../admin/components/core/EditMarqueModal.vue";

export default {
  name: "FluxListe",
  components: {
    AssignedCategoryModal,
    EditMarqueModal,
    NewMarqueModal,
    Multiselect,
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
  },
  data() {
    return {
      sendMarqueInfo: [],
      AssignedCategoryDialog: false,
      assignedSubCategory: [],
      lastVisibleMark: null,
      total: 0,
      pagination: 0,
      perPage: 10,
      MarquesListe: [],
      CategoriesList: [],
      CategoriesListmap: new Map(),
      categoryfilter: null,
      userID: null,
      NewMarqueDialog: false,
      EditMarqueDialog: false,
      CategoryDifference: [],
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
      filter: null,
    };
  },

  methods: {
    // Modification liste  categories
    editCategory(MarqueListe) {
      this.CategoryDifference = FunctionsUtils.getDifference(
        FunctionsUtils.getFields(this.CategoriesList, "name"),
        MarqueListe.assignedCategoryName
      );

      this.sendMarqueInfo = MarqueListe;
      this.AssignedCategoryDialog = true;
    },
    //Modification Marque Info (Nom, Libelle, Level, Order, ParentId, Image)

    // Flitrage Liste des Marques selon categories
    onCategoryChange: function (categoryfilter) {
      this.categoryfilter = categoryfilter;
      this.lastVisibleMark = null;
      this.MarquesListe = [];
      this.getMark();
    },
    // Flitrage Liste des Marques selon Ordre Alphabétique
    onFilterChange: function (filter) {
      this.filter = filter;
      this.lastVisibleMark = null;
      this.MarquesListe = [];
      this.getMark();
    },
    // Actualisation Liste des Marques
    clear() {
      this.CategoryDifference = [];
      this.MarquesListe = [];
      this.getMark();
      this.lastVisibleMark = null;
    },

    // Recuperation Liste des Marques
    async getMark() {
      this.CategoriesList = [];
      await this.getCategories();
      this.MarquesListe = [];
      var self = this;
      this.total = 200;
      this.pagination = Math.ceil(this.total / this.perPage);
      var query = db.collection(NodesName.PRODUCT_TO_SHARE_MARK);
      if (this.categoryfilter) {
        query = query.where(
          nodesFieldName.CATEGORY,
          "array-contains",
          this.categoryfilter
        );
      }

      if (this.filter == "Trier de A à Z") {
        query = query.orderBy(nodesFieldName.NAME, "asc");
      } else if (this.filter == "Trier de Z à A") {
        query = query.orderBy(nodesFieldName.NAME, "desc");
      } else {
        query = query.orderBy(NodesFieldName.NAME, "asc");
      }
      await query
        .limit(this.perPage)
        .where(NodesFieldName.DELETED, "==", false)
        .get()
        .then(function (querySnapshot) {
          let i = 0;
          querySnapshot.forEach((Marque) => {
            self.MarquesListe.push({
              id: Marque.data().uniqueId,
              libelle: Marque.data().libelle,
              name: Marque.data().name,
              src: Marque.data().image,
              flex: 6,
              assignedCategoryId: Marque.data().category,
              assignedCategoryName: FunctionsUtils.getassignedCategoryName(
                Marque.data().category,
                self.CategoriesListmap
              ),
              assignedSubCategory: Marque.data().subCategory,
              level: Marque.data().level,
              order: Marque.data().order,
              parentId: Marque.data().parentId,
            });

            i++;
            if (querySnapshot.size == i) {
              self.lastVisibleMark = Marque;
            }
          });
        });
    },
    // Recuperation des Marque  lors du pagination
    async onPaginationGetMark() {
      var self = this;
      var query = db.collection(NodesName.PRODUCT_TO_SHARE_MARK);
      if (this.categoryfilter) {
        query = query.where("category", "array-contains", this.categoryfilter);
      }

      if (this.filter == "Trier de A à Z") {
        query = query.orderBy(nodesFieldName.NAME, "asc");
      } else if (this.filter == "Trier de Z à A") {
        query = query.orderBy(nodesFieldName.NAME, "desc");
      } else {
        query = query.orderBy(NodesFieldName.NAME, "asc");
      }
      await query
        .limit(this.perPage)
        .startAfter(self.lastVisibleMark)
        .get()
        .then(function (querySnapshot) {
          let i = 0;
          querySnapshot.forEach((Marque) => {
            self.MarquesListe.push({
              id: Marque.data().uniqueId,
              libelle: Marque.data().libelle,
              name: Marque.data().name,
              src: Marque.data().image,
              flex: 6,
              assignedCategoryName: FunctionsUtils.getassignedCategoryName(
                Marque.data().category,
                self.CategoriesListmap
              ),
              assignedCategoryId: Marque.data().category,
              level: Marque.data().level,
              order: Marque.data().order,
              parentId: Marque.data().parentId,
            });

            i++;
            if (querySnapshot.size == i) {
              self.lastVisibleMark = Marque;
            }
          });
        });
    },
    // Recuperation liste des noms des Categories pour filtrage
    async getCategories() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
        .where(NodesFieldName.DELETED, "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.CategoriesListmap.set(doc.data().uniqueId, doc.data().name);
            this.CategoriesList.push({
              name: doc.data().name,
              id: doc.data().uniqueId,
            });
          });
        });
    },
    redirectionMarkDetails(MarqueListe) {
      this.$router.push({
        name: "MarkDetail",
        params: {
          Mark: MarqueListe,
        },
      });
    },
  },
  created() {
    this.getMark();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
