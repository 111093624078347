<template>
  <v-layout row justify-center>
    <v-dialog v-model="EditMarqueDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline cyan darken-1" primary-title>
          <v-icon
            @click="deleteDialog = true"
            style="
              position: absolute;
              top: 25px;
              right: 15px;
              color: red;
              font-size: 40px;
              cursor: pointer;
            "
            >mdi-delete-forever</v-icon
          >
          <v-dialog v-model="deleteDialog" width="500px">
            <v-card>
              <v-card-title class="headline cyan darken-1" primary-title>
                <h4 class="mb-3 justify-center white--text">Confirmation</h4>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  Êtes vous sur de supprimer la marque "
                  <span style="text-decoration: underline; color: #00acc1">
                    {{ Mark.name }}
                  </span>
                </div>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-2" text @click="deleteDialog = false">
                  Non
                </v-btn>
                <v-btn
                  color="#19b4bf"
                  text
                  v-on:click.prevent="SupprimerMarque()"
                >
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <h4 class="mb-3 justify-center white--text">
            Modification Marque <v-spacer></v-spacer>" {{ Mark.name }}"
          </h4>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- Nom de la Marque  -->
            <v-col class="justify-center">
              <v-text-field
                cols="12"
                sm="6"
                v-model="Mark.name"
                label="Nom de la Marque"
              ></v-text-field>
            </v-col>
            <!-- Libelle de la Marque  -->
            <v-col class="justify-center">
              <v-text-field
                v-model="Mark.libelle"
                label="libelle"
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="justify-center">
              <v-row class="justify-center">
                <!-- Level de la Marque  -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="Mark.level"
                    label="Level"
                    outlined
                    type="number"
                    clearable
                  ></v-text-field>
                </v-col>
                <!-- Order de la Marque  -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="Mark.order"
                    label="Order"
                    outlined
                    type="number"
                    clearable
                  ></v-text-field>
                </v-col>
                <!-- ParentId de la Marque  -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="Mark.parentId"
                    label="ParentId"
                    outlined
                    type="number"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <!-- Image de la Marque  -->
            <v-col class="justify-center">
              <v-img
                style="margin-left: 140px"
                contain
                max-height="295"
                max-width="210"
                :src="Mark.src"
              ></v-img>
              <!-- Modification Image  de la Marque  -->
              <v-file-input
                class="justify-center"
                hide-input
                prepend-icon="mdi-camera"
                v-model="images"
                label="Remplacer l'image"
                @change="uploadNewImageMarque()"
                @click:clear="callItBack()"
              ></v-file-input>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Confirmation Modification  de la Marque  -->
          <v-btn color="blue darken-1" text @click="close()"> Annuler </v-btn>
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-6"
                color="blue darken-1"
                text
                v-bind="attrs"
                v-on="on"
                :disabled="
                  (Mark.name && Mark.name.trim() === '') ||
                  !Mark.name ||
                  (Mark.name && Mark.name.match(/^ *$/)) ||
                  (Mark.libelle && Mark.libelle.trim() === '') ||
                  !Mark.libelle ||
                  (Mark.libelle && Mark.libelle.match(/^ *$/)) ||
                  !Mark.parentId ||
                  !Mark.order ||
                  !Mark.level ||
                  !Mark.src
                "
              >
                Confirmer
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline cyan darken-1" primary-title>
                <h4 class="mb-3 justify-center white--text">Confirmation</h4>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  Êtes vous sur de modifier la Marque avec nouveaux nom

                  <span style="text-decoration: underline; color: #00acc1">{{
                    Mark.name
                  }}</span>
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red lighten-2" text @click="dialog = false">
                  Non
                </v-btn>
                <v-btn color="#19b4bf" text v-on:click.prevent="EditMarque()">
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { firebase } from "@firebase/app";
import NodesName from "../../../utils/nodesName";
import NodesFieldName from "../../../utils/nodesFieldName";
import "firebase/firestore";
import db from "../../../../firebaseInit";
import FunctionsUtils from "../../../utils/functions";

export default {
  name: "AddMarque",
  data() {
    return {
      deleteDialog: false,
      images: [],
      userID: null,
      images: [],
      image: [],
      dialog: false,
    };
  },
  // Importation UserID
  async beforeMount() {
    this.userID = localStorage.getItem("adminID");
  },
  methods: {
    async SupprimerMarque() {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.UNIQUE_ID, "==", this.Mark.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                deleted: true,
                deletedBy: this.userID,
                deleteDate: new Date(),
              })
              .then(() => {
                this.$emit("update:EditMarqueDialog", false);
                this.$emit("Refrech");
              });
          });
        });
    },

    // Confirmation Modification Marque
    EditMarque() {
      db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.UNIQUE_ID, "==", this.Mark.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref
              .update({
                name: FunctionsUtils.capitalizeFirstLetter(this.Mark.name),
                image: this.Mark.src,
                level: Number(this.Mark.level),
                order: Number(this.Mark.order),
                parentId: Number(this.Mark.parentId),
                libelle: FunctionsUtils.capitalizeFirstLetter(
                  this.Mark.libelle
                ),
                updatedBy: this.userID,
                updatedDate: new Date(),
              })
              .then(() => {
                this.$emit("update:EditMarqueDialog", false);
                this.$emit("Refrech");
              });
          });
        });
    },
    //Upload Image dans Firebase Storage
    async uploadNewImageMarque() {
      await firebase
        .storage()
        .ref("mark_logo/" + this.Mark.name + "/" + "/" + this.images.name)
        .put(this.images)
        .then((response) => {
          firebase
            .storage()
            .ref("mark_logo/" + this.Mark.name + "/" + "/" + this.images.name)
            .getDownloadURL()
            .then((url) => {
              this.Mark.src = url;
            })
            .catch(function (error) {
              console.log(error);
            });
        });
    },
    //Fermeture du Dialog de modification
    close() {
      this.$emit("update:EditMarqueDialog", false);
      this.$emit("Refrech");
    },
  },
  props: {
    Mark: {
      required: true,
    },
    EditMarqueDialog: {
      type: Boolean,
    },
  },
};
</script>
